/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPCM402P
 * 화면설명: 상세인별현황
 * 작 성 일: 2022.07.07
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="false" title="상세인별현황" type="popup" @on-scroll-bottom="fn_MoreList()">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area">

        <!-- 리스트 영역 -->
        <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_005" direction="column" class="ns-info-list recruiting">
          <mo-list :list-data="whlCndtrList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">후보자</span><span class="value">{{ item.cndtrNm }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">추천FC</span><span class="value">{{ item.recmFcNm }} ({{ item.recmFcEno }})</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">후보자등록일</span><span class="value">{{ item.cndtrRegYmd }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">적격여부</span><span class="value">{{ item.qlfYn }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">세미나</span><span class="value">{{ item.semPlnYmd }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">인터뷰</span><span class="value">{{ item.intviRsltYmd }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">TS</span><span class="value">{{ item.tsRsltYmd }}</span>
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="w120">입과확보</span><span class="value">{{ item.encrsQurntYn }}</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>

          <!-- 버튼 영역 -->
          <!-- <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-round-area">
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="$emit('onPopupClose')">확인</mo-button>
          </ur-box-container> -->
        </ur-box-container>

        <!-- NoData 영역  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: "MSPRC402P",
  screenId: "MSPRC402P",
  components: {},
  props: {
    pPnstaInqrObj: Object
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right'
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      whlCndtrList: [], // 전체 후보자 리스트
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: ''}],
      stndKeyVal: ''
    }
  },
 
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    this.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC402P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명: fn_Init
     * 설명: 최초 진입
     ******************************************************************************/
    fn_Init () {
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: ''}]
      this.stndKeyVal = '' // 더보기 최초조회
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.whlCndtrList = [] // 초기화

      // 현황 인별조회
      this.fn_SelPnstaInqrByPsn()
    },

    /******************************************************************************
     * Function명: fn_SelPnstaInqrByPsn
     * 설명: 현황 인별조회
     ******************************************************************************/
    fn_SelPnstaInqrByPsn () {
      let lv_Vm = this

      this.isLoading = true

      let pParams = {
        cnsltEno: '', // 컨설턴트사번
        hqOrgNo: lv_Vm.pPnstaInqrObj.hqOrgNo, // 본부조직코드
        dofOrgNo: lv_Vm.pPnstaInqrObj.dofOrgNo, // 지역단조직코드
        fofOrgNo: lv_Vm.pPnstaInqrObj.fofCd, // 지점조직코드
        inqrStrYmd: lv_Vm.pPnstaInqrObj.inqrStrYmd, // 조회 시작일자
        inqrEndYmd: lv_Vm.pPnstaInqrObj.inqrEndYmd, // 조회 종료일자
        inqrScCd: lv_Vm.pPnstaInqrObj.inqrScCd // 조회 구분코드
      }
      let trnstId = 'txTSSRC50S2'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '' && response.body.msgTyp === 'S') {
            let result = response.body.whlCndtrList
            if (result !== null && result.length !== 0) {
              for (let i = 0; i < result.length; i++) {
                let tempObj = lv_Vm.$bizUtil.cloneDeep(result[i])
                tempObj.cndtrRegYmd = tempObj.cndtrRegYmd !== '00000000' ? moment(tempObj.cndtrRegYmd).format('YYYY-MM-DD') : '-' // 후보자등록일
                tempObj.semPlnYmd = tempObj.semPlnYmd !== '00000000' ? moment(tempObj.semPlnYmd).format('YYYY-MM-DD') : '-' // 세미나
                tempObj.intviRsltYmd = tempObj.intviRsltYmd !== '00000000' ? moment(tempObj.intviRsltYmd).format('YYYY-MM-DD') : '-' // 인터뷰
                tempObj.tsRsltYmd = tempObj.tsRsltYmd !== '00000000' ? moment(tempObj.tsRsltYmd).format('YYYY-MM-DD') : '-' // TS
                lv_Vm.whlCndtrList.push(tempObj) // 후보자 리스트 TABLE
              }

              if (result.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
            // 더보기 키값
            lv_Vm.stndKeyList = response.trnstComm.stndKeyList
            if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              lv_Vm.stndKeyVal = 'end' // 다음페이지 데이터 존재안함(마지막 페이지)
            } else {
              lv_Vm.stndKeyVal = 'more' // 다음페이지 데이터 존재함
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_MoreList
      * 설명       : 페이징 조회
      * this.stndKeyVal -> more then 조회.
      *                 -> end then 조회마지막
    ******************************************************************************/
    fn_MoreList () {
      if (this.stndKeyVal === 'end') return
      this.stndKeyVal = '' // 더보기 최초조회
      this.fn_SelPnstaInqrByPsn()
    }

  }
}
</script>