/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPCM400M
 * 화면설명: 리크루팅 현황
 * 작 성 일: 2022.07.07
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="true" title="리크루팅 현황" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPRC401P()" @on-scroll-top="fn_ModalOpen()">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">

        <!-- 리스트 영역 -->
        <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_007" direction="column" class="ns-contract-list ns-only">
          <div @touchstart="fn_ModalClose()">
            <mo-list-item v-for="item in whlOrgList" :key="item.fofNm">
              <!-- 지점 리스트 -->
              <div class="list-item__contents" v-if="!isShowDofOrg">
                <div class="list-item__contents__title">
                  <!-- 지점명 -->
                  <span class="name txtSkip">{{ item.fofNm }}</span>
                </div>
                <div class="list-item__contents__info">
                  <div class="ns-side-side">
                    <div class="sum-value">
                      <span class="w100">리크루팅 동의</span>
                      <span v-if="item.recrCnsntCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '1')">{{ item.recrCnsntCnt }}</span>
                      <span v-else class="txtBlue">{{ item.recrCnsntCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">세미나</span>
                      <span v-if="item.semAtdncCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '2')">{{ item.semAtdncCnt }}</span>
                      <span v-else class="txtBlue">{{ item.semAtdncCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">인터뷰</span>
                      <span v-if="item.intviRsltCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '3')">{{ item.intviRsltCnt }}</span>
                      <span v-else class="txtBlue">{{ item.intviRsltCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">TS</span>
                      <span v-if="item.tsRsltCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '4')">{{ item.tsRsltCnt }}</span>
                      <span v-else class="txtBlue">{{ item.tsRsltCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">입과확보</span>
                      <span v-if="item.encrsQurntCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '5')">{{ item.encrsQurntCnt }}</span>
                      <span v-else class="txtBlue">{{ item.encrsQurntCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">후보자인원수</span>
                      <span v-if="item.cndtrCnt !== '0'" class="txtBlue" @click="fn_OpenMSPRC402P(item, '6')">{{ item.cndtrCnt }}</span>
                      <span v-else class="txtBlue">{{ item.cndtrCnt }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 지역단 리스트 -->
              <div class="list-item__contents" v-else>
                <div class="list-item__contents__title">
                  <!-- 지역단명 -->
                  <span class="name txtSkip">{{ item.dofOrgNm }}</span>
                </div>
                <div class="list-item__contents__info">
                  <div class="ns-side-side">
                    <div class="sum-value">
                      <span class="w100">리크루팅 동의</span>
                      <span class="txtBlue">{{ item.recrCnsntCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">세미나</span>
                      <span class="txtBlue">{{ item.semAtdncCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">인터뷰</span>
                      <span class="txtBlue">{{ item.intviRsltCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">TS</span>
                      <span class="txtBlue">{{ item.tsRsltCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">입과확보</span>
                      <span class="txtBlue">{{ item.encrsQurntCnt }}</span>
                    </div>
                    <div class="sum-value">
                      <span class="w100">후보자인원수</span>
                      <span class="txtBlue">{{ item.cndtrCnt }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </mo-list-item>
          </div>
        </ur-box-container>

        <!-- NoData 영역  -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_007" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>

        <!-- 합계 영역 -->
        <div class="ns-bottom-sheet without-btn">
          <!-- __prevent-back-close : 바텀시트가 open 되어 있을때 뒤로가기제한 2022-08-05 김미정 프로 -->
          <mo-bottom-sheet ref="bottomSheet_SUM" __prevent-back-close>
            <template v-slot:title>합계</template>
            <div class="ns-side-side">
              <div class="sum-value">
                <span class="w100">리크루팅 동의</span>
                <span class="txtBlue">{{ totalData.recrCnsntTotCnt.trim() !== '' ? totalData.recrCnsntTotCnt : '0' }}</span>
              </div>
              <div class="sum-value">
                <span class="w100">세미나</span>
                <span class="txtBlue">{{ totalData.semAtdncTotCnt.trim() !== '' ? totalData.semAtdncTotCnt : '0' }}</span>
              </div>
              <div class="sum-value">
                <span class="w100">인터뷰</span>
                <span class="txtBlue">{{ totalData.intviTotCnt.trim() !== '' ? totalData.intviTotCnt : '0' }}</span>
              </div>
              <div class="sum-value">
                <span class="w100">TS</span>
                <span class="txtBlue">{{ totalData.tsEfTotCnt.trim() !== '' ? totalData.tsEfTotCnt : '0' }}</span>
              </div>
              <div class="sum-value">
                <span class="w100">입과확보</span>
                <span class="txtBlue">{{ totalData.encrsQurntTotCnt.trim() !== '' ? totalData.encrsQurntTotCnt : '0' }}</span>
              </div>
              <div class="sum-value">
                <span class="w100">후보자인원수</span>
                <span class="txtBlue">{{ totalData.cndtrTotCnt.trim() !== '' ? totalData.cndtrTotCnt : '0' }}</span>
              </div>
            </div>
          </mo-bottom-sheet>
        </div>

        <!-- Toast -->
        <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import SearchDetail from '@/ui/rc/SearchDetail.vue'
import MSPRC401P from '@/ui/rc/MSPRC401P.vue' // 리크루팅 현황 상세조회조건 팝업
import MSPRC402P from '@/ui/rc/MSPRC402P.vue' // 리크루팅 상세인별현황 팝업

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPRC400M',
  screenId: 'MSPRC400M',
  mixins:[Screen],
  components: {
    SearchDetail,
    MSPRC401P,
    MSPRC402P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      // 팝업 연결 변수
      popup401: {}, // MSPRC401P 리크루팅 현황 상세조회조건
      popup402: {}, // MSPRC402P 상세인별현황
      pOrgData400M: {}, // 최근 조직 조회 데이터
      startDate: '', // 조회시작일자
      endDate: '', // 조회종료일자

      // 조회 변수
      userInfo: {}, // 사용자정보 obj
      isEmptyView: false, // 데이터 없음 화면
      whlOrgList: [], // 전체 리스트
      orgNm: '지점',
      totalData: {
        recrCnsntTotCnt: '', // 리크루팅동의건수합계
        semAtdncTotCnt: '', // 세미나참석건수합계
        intviTotCnt: '', // 인터뷰건수합계
        tsEfTotCnt: '', // TS건수합계
        encrsQurntTotCnt: '', // 입과확보건수합계
        cndtrTotCnt: '' // 후보자건수합계
      },
      isShowDofOrg: false, // 지역단,지점 구분
      isSearched: false, // 조회이력 저장 변수

      // 조직 컴포넌트 데이터
      lv_SelectOrg: [],
      hqOrgCd: '', // 본부조직코드
      dofOrgCd: '', // 지역단조직코드
      fofOrgCd: '', // 지점조직코드
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
 /** computed 함수 정의 영역 */
  computed: {},
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    const lv_Vm = this

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, lv_Vm.fn_HistoryBack)

    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)

    lv_Vm.userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo

    // 최초 진입시 날짜 세팅
    lv_Vm.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC400M')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.pOrgData400M = {} // 조직 조회 데이터
        this.startDate = this.$bizUtil.getFirstDayOfNowMonth('String') // 조회시작일자
        this.endDate = moment(new Date()).format('YYYY-MM-DD') // 조회종료일자

        this.isSearched = false // 검색이력 초기화

        // 현황 조회
        this.fn_SelPnstaInqr()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    },

    /******************************************************************************
     * Function명: fn_Init
     * 설명: 최초 진입시 날짜 세팅
     ******************************************************************************/
    fn_Init () {
      this.startDate = this.$bizUtil.getFirstDayOfNowMonth('String') // 이번달 1일
      this.endDate = moment(new Date()).format('YYYY-MM-DD') // 현재날짜

      // 현황 조회
      this.fn_SelPnstaInqr()
    },

    /******************************************************************************
     * Function명 : fn_SelPnstaInqr
     * 설명       : 현황 조회
     ******************************************************************************/
    fn_SelPnstaInqr() {
      // 최초 조회시 userInfo에서 정보 가져옴
      if (!_.isEmpty(this.pOrgData400M)) {
        if (this.pOrgData400M.dofOrg.label === '선택') {
          this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', '조직 정보를 선택해 주세요.')
          return
        }

        if (this.pOrgData400M.dofOrg.label === '전체') {
          this.orgNm = '지역단'
          this.isShowDofOrg = true
        } else {
          this.orgNm = '지점'
          this.isShowDofOrg = false
        }

        this.hqOrgCd = this.pOrgData400M.hofOrg.key // 본부조직코드
        this.dofOrgCd = this.pOrgData400M.dofOrg.key // 지역단조직코드
        this.fofOrgCd = this.pOrgData400M.fofOrg.key // 지점조직코드
      } else {
        this.hqOrgCd = this.userInfo.onpstHofOrgNo // 본부조직코드
        this.dofOrgCd = this.userInfo.onpstDofNo // 지역단조직코드
        this.fofOrgCd = this.userInfo.onpstFofOrgNo // 지점조직코드
      }

      let lv_Vm = this
      let pParams = {
        cnsltEno: '', // 컨설턴트사번
        hqOrgCd: lv_Vm.hqOrgCd, // 본부조직코드
        dofOrgCd: lv_Vm.dofOrgCd, // 지역단조직코드
        fofOrgCd: lv_Vm.fofOrgCd, // 지점조직코드
        inqrStrYmd: this.startDate.replace(/-/gi, '').substring(0, 8), // 조회 시작일자
        inqrEndYmd: this.endDate.replace(/-/gi, '').substring(0, 8) // 조회 종료일자
      }
      let trnstId = 'txTSSRC50S1'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '' && response.body.msgTyp === 'S') {
            let result = response.body.whlOrgList

            lv_Vm.whlOrgList = [] // 초기화

            if (result !== null && result.length !== 0) {
              for (let i = 0; i < result.length; i++) {
                // 더미데이터 제거(2022-08-02)
                if (!lv_Vm.$bizUtil.isEmpty(result[i].fofCd) && !lv_Vm.$bizUtil.isEmpty(result[i].fofNm)) {
                  let tempObj = lv_Vm.$bizUtil.cloneDeep(result[i])
                  lv_Vm.whlOrgList.push(tempObj) // 후보자 리스트 TABLE
                }
              }

              if (lv_Vm.whlOrgList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }
            }
            lv_Vm.totalData = lv_Vm.$bizUtil.cloneDeep(response.body)

            lv_Vm.fn_ModalOpen()
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },
  
    /******************************************************************************
     * Function명: fn_ModalOpen
     * 설명: 합계 Modal Open
     ******************************************************************************/
    fn_ModalOpen () {
      this.$refs.bottomSheet_SUM.open()
    },

    /******************************************************************************
     * Function명: fn_ModalClose
     * 설명: 합계 Modal Close
     ******************************************************************************/
    fn_ModalClose () {
      if (this.whlOrgList.length > 3) this.$refs.bottomSheet_SUM.close()
    },

    /******************************************************************************
     * Function명: fn_OpenMSPRC401P
     * 설명: 리크루팅 현황 상세조회조건 팝업 호출
     ******************************************************************************/
    fn_OpenMSPRC401P () {
      this.popup401 = this.$bottomModal.open(MSPRC401P, {
        properties: {
          pOrgData400M: this.pOrgData400M, // 최근 조직 조회 데이터
          pStartDate: this.startDate, // 조회시작일자
          pEndDate: this.endDate, // 조회종료일자
        },
        listeners: {
          onPopupSearch: (pData) => {
            this.$bottomModal.close(this.popup401)

            this.startDate = pData.rtnData.startDate // 조회시작일자
            this.endDate = pData.rtnData.endDate // 조회종료일자

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData400M = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 현황 조회
            this.fn_SelPnstaInqr()
          }
        }
      })
    },

    /******************************************************************************
     * Function명: fn_OpenMSPRC402P
     * 설명: 상세인별현황 팝업 호출
     ******************************************************************************/
    fn_OpenMSPRC402P (item, inqrScCd) {
      let pnstaInqrObj = {}

      pnstaInqrObj = item
      pnstaInqrObj.inqrStrYmd = this.startDate.replace(/-/gi, '').substring(0, 8) // 조회 시작일
      pnstaInqrObj.inqrEndYmd = this.endDate.replace(/-/gi, '').substring(0, 8) // 조회 종료일
      pnstaInqrObj.inqrScCd = inqrScCd // 조회 구분코드

      this.popup402 = this.$bottomModal.open(MSPRC402P, {
        properties: {
          pPnstaInqrObj: pnstaInqrObj
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup402)
          }
        }
      })
    }

  }
}
</script>
